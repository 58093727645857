<template>
  <div>
    <div>
      <b-skeleton-wrapper>
        <template #loading>
          <b-skeleton width="100%" />
          <b-skeleton width="75%" />
          <b-skeleton width="50%" />
        </template>
        <b-card
          v-if="!isUserStripeConnected"
          class="card"
        >
          <div class="bg-white d-flex justify-content-between">
            <div>
              <img
                src="../../assets/images/icons/stripe.jpg"
                width="50"
                height="50"
                alt=""
              >
            </div>
            <div
              v-if="!accessTokenUser"
              class="d-flex align-items-center"
            >
              Get Started with Stripe Connect.
            </div>
            <div class="d-flex align-items-center">
              <b-button
                v-if="!accessTokenUser"
                variant="white"
                size="sm"
                block
                class="buttons"
                :disabled="btndisable"
                @click="getAcoundIdAndConnectStripe"
              >
                <span
                  v-if="!spinner"
                >
                  <img
                    src="../../assets/images/icons/stripe.jpg"
                    class="imagebtn"
                    alt=""
                  >
                  Connect
                </span>
                <div
                  v-else
                  small
                >
                  <b-spinner label="Loading..." />
                </div>
              </b-button>
            </div>
          </div>
        </b-card>
        <div
          v-if="isUserStripeConnected"
          class="px-2 admin-applications"
        >
          <!-- pricing plan cards -->
          <b-row class="pricing-card m-2 mt-0 mt-md-5">
            <b-col
              offset-sm-2
              sm="6"
              md="12"
              lg="12"
              class="mx-auto"
            >
              <b-row class="d-flex justify-content-center mt-2">
                <span>
                  <b-card
                    align="center"
                    class="m-4 p-4 mt-4 cursor-pointer"
                    @click="goToStripeConnectDashboard()"
                  >
                    <div class="align-items-center">
                      <div class="mt-1">
                        <img
                          src="../../assets/images/icons/stripe.jpg"
                          alt="application-icon"
                          style="max-width: 114px;"
                        >
                      </div>
                      <span
                        class="ml-1 font-weight-bolder group-name-class text-capitalize"
                      >
                        <div>Stripe Connect Dashboard</div>
                      </span>
                    </div>
                    <!--/ img -->
                  </b-card>
                </span>
              </b-row>
            </b-col>
          </b-row>
          <div
            v-if="spinner"
            class="modal-overlay"
          >
            <b-spinner label="Loading..." />
          </div>
        </div>
      </b-skeleton-wrapper>
    </div>
  </div>
</template>

<script>
import {
//   BTable,
  BButton,
  BCard,
  //   BSkeletonTable,
  BSkeletonWrapper,
  BSkeleton,
  VBTooltip,
  BRow,
  BCol,
  BSpinner,
//   BPagination,
} from 'bootstrap-vue'
import Vue from 'vue'
import Ripple from 'vue-ripple-directive'
import Vuesax from 'vuesax'
// import AddMedia from '@/components/common/canvaAddMedia.vue'
import 'vuesax/dist/vuesax.css'
// import { findAuth0SessionKey, showToast } from '@/common/global/functions'

Vue.use(Vuesax)
Vue.directive('ripple', Ripple)

export default {
  components: {
    // BTable,
    BButton,
    BCard,
    // BSkeletonTable,
    BSkeletonWrapper,
    BSkeleton,
    BRow,
    BCol,
    // AddMedia,
    BSpinner,
    // BPagination,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      spinner: false,
    }
  },
  computed: {
    user() {
      return this.$store.getters['user/getUser']
    },
    userSelectedGroup() {
      return this.$store.getters['user/getSelectedGroupMember']
    },
    isUserStripeConnected() {
      if (this.userSelectedGroup && this.userSelectedGroup.user_has_group.stripe_connect_account_id && this.userSelectedGroup.user_has_group.stripe_onBoarding) {
        return true
      }
      return false
    },
  },
  methods: {
    async getAcoundIdAndConnectStripe() {
      this.spinner = true
      const resp = await this.$axios.post('/stripe/create-stripe-connect', {
        email: this.user.email,
      })
      window.open(resp.data.data.url, '_self')
      this.spinner = false
    },
    async goToStripeConnectDashboard() {
      this.spinner = true
      const resp = await this.$axios.get(`/stripe/get-connect-dashboard-link?accountId=${this.userSelectedGroup.user_has_group.stripe_connect_account_id}`)
      window.open(resp.data.data, '_blank')
      this.spinner = false
    },
  },
}
</script>

  <style scoped>
  .card-body {
    padding: 0.75rem !important;
  }
  .title {
    max-width: 350px;
    min-width: 300px;
  }
  .buttons{
  border: 0.5px solid gray;
  padding-top: 6px;
  padding-bottom: 6px;
  }
  .buttonCreateDesign{
    padding-top: 9px;
    padding-bottom: 12px;
    }
  .imagebtn{
    width: 20px;
    border-radius: 50%;
    margin-bottom: 1px;
    margin-right: 3px;
  }
  .modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.416);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050; /* Ensure it sits above the modal content */
  }

  @media (max-width: 900px) {
    .title {
      max-width: 200px;
      min-width: 150px;
    }
  }
  @media (max-width: 763px) {
    .buttons{
      border: 0.5px solid gray;
      padding : 6px;
      font-size: x-small !important;
      }
      .buttonCreateDesign{
        padding-left : 9px;
        padding-right : 9px;
        padding-top : 6px;
        padding-bottom : 6px;
      font-size: x-small !important;
      }
    .imagebtn{
      width: 14px !important;
      border-radius: 50%;
      margin-bottom: 0px;
      margin-right: 0px;
    }
    .buttonsMedia{
      padding-left : 20px !important;
      padding-right : 20px !important;
      font-size: xx-small !important;
      display: inline;
      }

      .title {
        max-width: 150px;
        min-width: 100px;
      }
  }
  @media (max-width: 670px) {
    .title {
      max-width: 100px;
      min-width: 60px;
    }
    .imgBlock{
      max-width: 50px;
      min-width: 20px;
    }
  }
  </style>
